import React, { createContext, useState, useEffect } from "react";
import { collection, onSnapshot, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";

const AnimalOfficerContext = createContext();

const AnimalOfficerProvider = ({ children }) => {
  const [animalOfficers, setAnimalOfficers] = useState([]);

  const getAnimalOfficerReportCount = async (officerId) => {
    const reportsRef = collection(db, "animal_officer", officerId, "reports");
    const snapshot = await getDocs(reportsRef);
    return snapshot.size;
  };

  const getAnimalOfficerFarmCount = async (officerId) => {
    const farmsRef = collection(db, "animal_officer", officerId, "farms");
    const snapshot = await getDocs(farmsRef);
    return snapshot.size;
  };

  const getAnimalOfficerAnimalCount = async (officerId) => {
    const animalsRef = collection(db, "animal_officer", officerId, "animals");
    const snapshot = await getDocs(animalsRef);
    return snapshot.size;
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "animal_officer"),
      async (snapshot) => {
        const officersData = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const data = doc.data();
            const reportCount = await getAnimalOfficerReportCount(doc.id);
            const farmCount = await getAnimalOfficerFarmCount(doc.id);
            const animalCount = await getAnimalOfficerAnimalCount(doc.id);

            return {
              id: doc.id,
              ...data,
              reportCount,
              farmCount,
              animalCount,
            };
          })
        );
        setAnimalOfficers(officersData);
      },
      (error) => {
        console.error("Error fetching animal officers: ", error);
      }
    );

    return () => unsubscribe();
  }, []);

  return (
    <AnimalOfficerContext.Provider value={animalOfficers}>
      {children}
    </AnimalOfficerContext.Provider>
  );
};

export { AnimalOfficerContext, AnimalOfficerProvider };
