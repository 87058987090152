const Panel = ({ activeIndex, setActiveIndex }) => {
  const activeStyle = {
    backgroundColor: "black",
    color: "white",
    fontSize: "18px",
  };
  const inactiveStyle = { color: "grey", fontSize: "18px" };
  return (
    <div className="d-flex flex-column bg-white rounded-3 align-self-stetch px-3 py-3">
      <span className="pb-3 text-center" style={{ fontSize: "30px" }}>
        M-VET
      </span>
      <div className="">
        <button
          className="btn w-100 text-start py-3 d-flex justify-content-start"
          style={activeIndex === 0 ? activeStyle : inactiveStyle}
          onClick={() => setActiveIndex(0)}
        >
          <span className="pe-3">
            <i className="bi bi-people-fill"></i>
          </span>
          <span> Animal Officers</span>
        </button>
      </div>
      <div className="d-flex">
        <button
          className="btn w-100 text-start py-3 d-flex justify-content-start"
          style={activeIndex === 1 ? activeStyle : inactiveStyle}
          onClick={() => setActiveIndex(1)}
        >
          <span className="pe-3">
            <i className="bi bi-clipboard-data-fill"></i>
          </span>
          <span>Reports</span>
        </button>
      </div>
      <div className="d-flex">
        <button
          className="btn w-100 text-start py-3 d-flex justify-content-start"
          style={activeIndex === 2 ? activeStyle : inactiveStyle}
          onClick={() => setActiveIndex(2)}
        >
          <span className="pe-3">
            <span className="material-symbols-outlined">pets</span>
          </span>
          <span> Farms</span>
        </button>
      </div>
    </div>
  );
};

export default Panel;
