import { useState, useContext } from "react";
import { db, auth } from "../firebaseConfig";
import { collection, query, where, getDocs } from "firebase/firestore";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate, Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/auth";

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const signInUser = async () => {
    //validate input
    if (phoneNumber === "" || password === "") {
      alert("Please fill in all fields");
      return;
    }
    //check if email in admin list
    const email = phoneNumber + "@admin.mvet.vercel.app";
    const q = query(collection(db, "admins"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.size > 0) {
      try {
        await signInWithEmailAndPassword(auth, email, password);
        navigate("/");
      } catch (error) {
        alert("Invalid login credentials. Please try again.");
      }
    } else {
      alert(
        "You do not have permission to access this page. Please contact the admin for more information."
      );
    }
    return;
  };

  return user ? (
    <Navigate to="/" />
  ) : (
    <div
      className="row vh-100 align-items-center"
      style={{
        backgroundImage:
          "url('https://cdn.britannica.com/77/245977-050-49358C4E/Cattle-feedlot-west-texas-beef-industry.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="row justify-content-center">
        <div
          className="col-md-5 col-sm-12  rounded-start-4   py-5"
          style={{ backgroundColor: "rgba(255, 255,255,0.95)" }}
        >
          <div className="d-flex flex-column">
            <div className="d-flex flex-column align-items-center">
              <img
                src="https://cdn2.creativecirclemedia.com/pagosa/medium/20231116-152036-livestock-class-head.jpg"
                alt="logo"
                style={{ width: "100px" }}
              />
              <span className="fw-bold pb-3 pt-3" style={{ fontSize: "25px" }}>
                Login to dashboard
              </span>
            </div>
            <div className="px-5">
              <div className="mb-3 d-flex flex-column align-items-start">
                <label htmlFor="phoneNumber" className="form-label fw-semibold">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3 d-flex flex-column align-items-start">
                <label htmlFor="password" className="form-label fw-semibold">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="d-flex">
                <button
                  className="btn fw-bold w-100"
                  style={{
                    backgroundColor: "rgba(22, 163, 74, 1)",
                    color: "white",
                  }}
                  onClick={signInUser}
                >
                  LOGIN
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-5 col-sm-12 p-0 align-self-stretch d-flex flex-column rounded-end-4 justify-content-center "
          style={{ backgroundColor: "rgba(22, 163, 74, 1)", color: "white" }}
        >
          <span className="fw-bold pb-3" style={{ fontSize: "25px" }}>
            M-Vet
          </span>
          <span style={{ fontSize: "18px" }}>
            Join the M-Vet community today
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
