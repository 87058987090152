import { useState, useContext, useEffect } from "react";
import { AnimalOfficerContext } from "../contexts/animalOfficer";
import AnimalOfficerSummary from "./animalOfficerSummary";
import { ref, listAll } from "firebase/storage";
import { storage } from "../firebaseConfig";

const AnimalOfficers = () => {
  const animalOfficers = useContext(AnimalOfficerContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [filteredOfficers, setFilteredOfficers] = useState(animalOfficers);
  const officersPerPage = 3; // Set the number of officers per page

  useEffect(() => {
    let matchingOfficers = animalOfficers;

    if (name) {
      matchingOfficers = matchingOfficers.filter((officer) =>
        officer.name.toLowerCase().includes(name.toLowerCase())
      );
    }

    if (phoneNumber) {
      matchingOfficers = matchingOfficers.filter((officer) =>
        officer.phoneNumber.includes(phoneNumber)
      );
    }

    setFilteredOfficers(matchingOfficers);
  }, [animalOfficers, name, phoneNumber]);

  useEffect(() => {
    var start = 0;
    const animalOfficerListRef = ref(storage, "animal_officer/");
    listAll(animalOfficerListRef).then((res) => {
      res.prefixes.forEach((folderRef) => {
        const animalsListRef = ref(storage, `${folderRef.fullPath}/animals`);
        listAll(animalsListRef)
          .then((res) => {
            res.items.forEach((itemRef) => {
              start++;
              console.log(start);
            });
          })
          .catch((error) => {
            console.log(error);
          });
      });
    });
  }, []);

  // Calculate the indices for slicing the officers array
  const indexOfLastOfficer = currentPage * officersPerPage;
  const indexOfFirstOfficer = indexOfLastOfficer - officersPerPage;
  const currentOfficers = filteredOfficers.slice(
    indexOfFirstOfficer,
    indexOfLastOfficer
  );

  // Calculate total pages
  const totalPages = Math.ceil(filteredOfficers.length / officersPerPage);

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Function to handle the "Previous" button click
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Function to handle the "Next" button click
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Calculate the page numbers to be displayed
  const pageNumbers = [];
  const maxPageNumbers = 3; // Number of page numbers to show
  let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
  let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

  if (endPage - startPage < maxPageNumbers - 1) {
    startPage = Math.max(1, endPage - maxPageNumbers + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <div
        className="d-flex rounded-4 px-5 py-3 text-white mx-4"
        style={{
          backgroundColor: "rgba(22, 163, 74, 1)",
          position: "relative",
          top: "50px",
        }}
      >
        <div className="me-3">
          <label htmlFor="name" className="form-label fw-semibold">
            Filter by Name
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            value={name}
            placeholder="Search Name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="me-3">
          <label htmlFor="phoneNumber" className="form-label fw-semibold">
            Filter by Phone Number
          </label>
          <input
            type="text"
            className="form-control"
            id="phoneNumber"
            value={phoneNumber}
            placeholder="Search Phone Number"
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
      </div>
      <div className="rounded-3 pt-5" style={{ backgroundColor: "white" }}>
        <div className="row fw-bold px-5" style={{ paddingTop: "30px" }}>
          <div className="col-md-4 text-start">Name</div>
          <div className="col-md-3 text-start">Phone Number</div>
          <div className="col-md-3 text-start">District</div>
          <div className="col-md-2"></div>
        </div>
        {currentOfficers.map((officer) => (
          <div key={officer.id}>
            <hr />
            <div className="row align-items-start px-5">
              <div className="col-md-4 text-start fw-medium">
                {officer.name}
              </div>
              <div className="col-md-3 text-start">{officer.phoneNumber}</div>
              <div className="col-md-3 text-start">{officer.district}</div>
              <div className="col-md-2 d-flex text-start">
                <button
                  className="btn fw-bold"
                  style={{
                    backgroundColor: "rgba(22, 163, 74, 1)",
                    color: "white",
                  }}
                  data-bs-toggle="modal"
                  data-bs-target={`#${officer.id}`}
                  onClick={() => {
                    const listRef = ref(
                      storage,
                      `animal_officer/${officer.id}/animals`
                    );
                    listAll(listRef)
                      .then((res) => {
                        res.prefixes.forEach((folderRef) => {
                          console.log(folderRef.fullPath);
                        });
                        res.items.forEach((itemRef) => {
                          console.log(itemRef.fullPath);
                        });
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }}
                >
                  View
                </button>
                <AnimalOfficerSummary
                  farms={officer.farmCount}
                  animals={officer.animalCount}
                  reports={officer.reportCount}
                  name={officer.name}
                  modalId={officer.id}
                />
              </div>
            </div>
          </div>
        ))}
        <div className="row pt-3">
          <div className="col-md-12 d-flex justify-content-center">
            <nav>
              <ul className="pagination">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={handlePrevious}
                    style={{ color: "rgba(22, 163, 74, 1)" }}
                  >
                    Previous
                  </button>
                </li>
                {pageNumbers.map((number) => (
                  <li
                    key={number}
                    className={`page-item ${
                      currentPage === number ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => paginate(number)}
                      style={
                        currentPage === number
                          ? {
                              color: "white",
                              backgroundColor: "rgba(22, 163, 74, 1)",
                            }
                          : { color: "rgba(22, 163, 74, 1)" }
                      }
                    >
                      {number}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={handleNext}
                    style={{ color: "rgba(22, 163, 74, 1)" }}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnimalOfficers;
