import { createContext, useState, useEffect } from "react";
import {
  collection,
  onSnapshot,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

const ReportContext = createContext();

const ReportProvider = ({ children }) => {
  const [reports, setReports] = useState([]);

  // Function to get the count of animals in a report's subcollection
  const getReportAnimalCount = async (reportId, officerId) => {
    try {
      const q = query(
        collection(db, "animal_officer", officerId, "animals"),
        where("reportId", "==", reportId)
      );
      const snapshot = await getDocs(q);
      return snapshot.size;
    } catch (error) {
      console.error("Error getting animal count: ", error);
    }
  };

  // Function to get all reports for a specific animal officer
  const getReportsForOfficer = async (officerId, officerName) => {
    try {
      const reportsRef = collection(db, "animal_officer", officerId, "reports");
      const snapshot = await getDocs(reportsRef);
      const reportsData = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const data = doc.data();
          const animalCount = await getReportAnimalCount(doc.id, officerId);
          return {
            id: doc.id,
            ...data,
            animalCount,
            submittedBy: officerName,
          };
        })
      );
      return reportsData;
    } catch (error) {
      console.error("Error getting reports: ", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "animal_officer"),
      async (snapshot) => {
        const reportsData = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const data = doc.data();
            const officerReports = await getReportsForOfficer(
              doc.id,
              data.name
            );
            return officerReports;
          })
        );
        setReports(reportsData.flat()); // Flatten the array of arrays
      },
      (error) => {
        console.error("Error fetching animal officers: ", error);
      }
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <ReportContext.Provider value={reports}>{children}</ReportContext.Provider>
  );
};

export { ReportContext, ReportProvider };
