import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
const ReportDetails = () => {
  const location = useLocation();
  const { report } = location.state;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  const [center, setCenter] = useState(null);

  const getReportAnimals = async (reportId, userId) => {
    try {
      const q = query(
        collection(db, "animal_officer", userId, "animals"),
        where("reportId", "==", reportId)
      );
      const snapshot = await getDocs(q);
      console.log("User id: ", userId);
      for (const doc of snapshot.docs) {
        console.log(doc.id, " => ", doc.data());
      }
    } catch (error) {
      console.error("Error getting animal count: ", error);
    }
  };

  useEffect(() => {
    const lat =
      report.route.reduce((acc, curr) => acc + curr.latitude, 0) /
      report.route.length;
    const lng =
      report.route.reduce((acc, curr) => acc + curr.longitude, 0) /
      report.route.length;
    setCenter({ lat, lng });

    getReportAnimals(report.reportId, report.submittedById);
  }, []);

  return isLoaded && center ? (
    <div className="bg-light">
      <div className="d-flex p-3">
        <div className="rounded-3 bg-white d-flex flex-column p-3 me-4">
          <span className="fw-semibold" style={{ fontSize: "20px" }}>
            Bio Security Measures
          </span>
          <ul>
            {report.bioSecurityMeasures.map((measure, index) => (
              <li key={`bioSecurityMeasure${index}`}>{measure}</li>
            ))}
          </ul>
        </div>
        <div className="rounded-3 bg-white d-flex flex-column p-3">
          <span className="fw-semibold" style={{ fontSize: "20px" }}>
            Feeding Mechanisms
          </span>
          <ul>
            {report.feedingMechanisms.map((mechanism, index) => (
              <li key={`feedingMechanisms${index}`}>{mechanism}</li>
            ))}
          </ul>
        </div>
      </div>
      <GoogleMap
        mapContainerStyle={{
          height: "100vh",
          width: "100%",
        }}
        center={center}
        zoom={11}
        options={{
          streetViewControl: false,
          mapTypeId: "roadmap",
          mapTypeControl: false,
        }}
      >
        {report.route.map((point, index) => (
          <MarkerF
            key={index}
            position={{ lat: point.latitude, lng: point.longitude }}
          />
        ))}
      </GoogleMap>
    </div>
  ) : (
    <div className="justify-content-center align-items-center vh-100 d-flex">
      <div
        className="spinner-border"
        role="status"
        style={{ color: "rgba(22, 163, 74, 1)" }}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default ReportDetails;
