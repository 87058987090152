import { AnimalOfficerContext } from "../contexts/animalOfficer";
import { useContext } from "react";
const Summary = () => {
  const animalOfficers = useContext(AnimalOfficerContext);
  const reportTotal = animalOfficers.reduce(
    (acc, officer) => acc + officer.reportCount,
    0
  );
  const farmTotal = animalOfficers.reduce(
    (acc, officer) => acc + officer.farmCount,
    0
  );
  const animalTotal = animalOfficers.reduce(
    (acc, officer) => acc + officer.animalCount,
    0
  );
  return (
    <div className="d-flex pb-2">
      <div className="rounded-3 d-flex bg-white p-3 me-3">
        <span className="fs-1 pe-5 align-self-top">
          <i className="bi bi-people-fill"></i>
        </span>
        <div className="d-flex flex-column">
          <span style={{ fontSize: "18px" }}>Animal Officers</span>
          <span className="fw-semibold text-end" style={{ fontSize: "30px" }}>
            {animalOfficers.length}
          </span>
        </div>
      </div>
      <div className="rounded-3 d-flex bg-white p-3 me-3">
        <span className="fs-1 pe-5 align-self-top">
          <i className="bi bi-file-bar-graph-fill"></i>
        </span>
        <div className="d-flex flex-column">
          <span style={{ fontSize: "18px" }}>Total Reports</span>
          <span className="fw-semibold text-end" style={{ fontSize: "30px" }}>
            {reportTotal}
          </span>
        </div>
      </div>
      <div className="rounded-3 d-flex bg-white p-3 me-3">
        <span className="pe-5 align-self-top">
          <span
            className="material-symbols-outlined"
            style={{ fontSize: "50px" }}
          >
            agriculture
          </span>
        </span>
        <div className="d-flex flex-column">
          <span style={{ fontSize: "18px" }}>Farms</span>
          <span className="fw-semibold text-end" style={{ fontSize: "30px" }}>
            {farmTotal}
          </span>
        </div>
      </div>
      <div className="rounded-3 d-flex bg-white p-3">
        <span className="fs-1 pe-5 align-self-top">
          <span
            className="material-symbols-outlined"
            style={{ fontSize: "40px" }}
          >
            pets
          </span>
        </span>
        <div className="d-flex flex-column">
          <span style={{ fontSize: "18px" }}>Animals</span>
          <span className="fw-semibold text-end" style={{ fontSize: "30px" }}>
            {animalTotal}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Summary;
