import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/login";
import Protected from "./components/protected";
import Dashboard from "./components/dashboard";
import ReportDetails from "./components/reportDetails";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/login" exact element={<Login />} />
          <Route
            path="/"
            element={
              <Protected path="/login">
                <Dashboard />
              </Protected>
            }
          />
          <Route
            path="/report_details"
            element={
              <Protected path="/login">
                <ReportDetails />
              </Protected>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
