const AnimalOfficerSummary = ({ modalId, farms, reports, animals, name }) => {
  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex="-1"
      aria-labelledby={modalId + "Label"}
      aria-hidden="true"
    >
      <div className="modal-dialog" style={{ width: "300px" }}>
        <div className="modal-content">
          <div
            className="fw-semibold text-center pt-3"
            style={{ fontSize: "18px" }}
          >
            {name}
          </div>
          <hr />
          <div className="d-flex flex-column">
            <div className="row px-3">
              <span className="fw-semibold col">Farms</span>
              <span className="col">{farms}</span>
            </div>
            <hr />
            <div className="row px-3">
              <span className="fw-semibold col">Reports</span>
              <span className="col">{reports}</span>
            </div>
            <hr />
            <div className="row px-3">
              <span className="fw-semibold col">Animals</span>
              <span className="col">{animals}</span>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-end pb-3 pe-3">
            <button
              type="button"
              className="btn"
              data-bs-dismiss="modal"
              style={{
                backgroundColor: "rgba(22, 163, 74, 1)",
                color: "white",
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimalOfficerSummary;
