import { useState, useContext, useEffect } from "react";
import { ReportContext } from "../contexts/report";
import { Link } from "react-router-dom";

const Reports = () => {
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [filteredReports, setFilteredReports] = useState([]);
  const reports = useContext(ReportContext);
  const [currentPage, setCurrentPage] = useState(1);
  const reportsPerPage = 3;

  useEffect(() => {
    let matchingReports = reports;

    if (name) {
      matchingReports = matchingReports.filter((report) =>
        report.submittedBy.toLowerCase().includes(name.toLowerCase())
      );
    }

    if (date) {
      const selectedDate = new Date(date);
      matchingReports = matchingReports.filter((report) => {
        const [day, month, year] = report.submissionDate.split("-");
        const reportDate = new Date(`${year}-${month}-${day}`);
        return reportDate.toDateString() === selectedDate.toDateString();
      });
    }

    setFilteredReports(matchingReports);
  }, [reports, name, date]);

  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = filteredReports.slice(
    indexOfFirstReport,
    indexOfLastReport
  );

  // Calculate total pages
  const totalPages = Math.ceil(filteredReports.length / reportsPerPage);

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Function to handle the "Previous" button click
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Function to handle the "Next" button click
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Calculate the page numbers to be displayed
  const pageNumbers = [];
  const maxPageNumbers = 3; // Number of page numbers to show
  let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
  let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

  if (endPage - startPage < maxPageNumbers - 1) {
    startPage = Math.max(1, endPage - maxPageNumbers + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <div
        className="d-flex rounded-4 px-5 py-3 text-white mx-4"
        style={{
          backgroundColor: "rgba(22, 163, 74, 1)",
          position: "relative",
          top: "50px",
        }}
      >
        <div className="me-3">
          <label htmlFor="name" className="form-label fw-semibold">
            Filter by Name
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            value={name}
            placeholder="Search Name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="date" className="form-label fw-semibold">
            Filter by Date
          </label>
          <input
            type="date"
            className="form-control"
            id="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
      </div>
      <div
        className="rounded-3"
        style={{ backgroundColor: "white", paddingTop: "75px" }}
      >
        <div className="row fw-bold px-5">
          <div className="col-md-4 text-start">Report Id</div>
          <div className="col-md-3 text-center">Number of animals</div>
          <div className="col-md-3 text-start">Submitted By</div>
          <div className="col-md-2"></div>
        </div>
        {currentReports.map((report) => (
          <div key={report.id}>
            <hr />
            <div className="row align-items-start px-5">
              <div className="col-md-4 text-start fw-medium">{report.id}</div>
              <div className="col-md-3 text-center">{report.animalCount}</div>
              <div className="col-md-3 text-start">{report.submittedBy}</div>
              <div className="col-md-2 d-flex text-start">
                <Link
                  to="/report_details"
                  state={{ report }}
                  className="btn fw-bold"
                  style={{
                    backgroundColor: "rgba(22, 163, 74, 1)",
                    color: "white",
                  }}
                >
                  Details
                </Link>
              </div>
            </div>
          </div>
        ))}
        <div className="row pt-3">
          <div className="col-md-12 d-flex justify-content-center">
            <nav>
              <ul className="pagination">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={handlePrevious}
                    style={{ color: "rgba(22, 163, 74, 1)" }}
                  >
                    Previous
                  </button>
                </li>
                {pageNumbers.map((number) => (
                  <li
                    key={number}
                    className={`page-item ${
                      currentPage === number ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => paginate(number)}
                      style={
                        currentPage === number
                          ? {
                              color: "white",
                              backgroundColor: "rgba(22, 163, 74, 1)",
                            }
                          : { color: "rgba(22, 163, 74, 1)" }
                      }
                    >
                      {number}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={handleNext}
                    style={{ color: "rgba(22, 163, 74, 1)" }}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
