import { useState, useEffect } from "react";
import Panel from "./panel";
import AnimalOfficers from "./animalOfficers";
import Reports from "./reports";
import Summary from "./summary";
import { functions } from "../firebaseConfig";
import { httpsCallable } from "firebase/functions";

const Dashboard = () => {
  const [activeIndex, setActiveIndex] = useState(0);


  return (
    <div className="row p-3 vh-100 bg-light">
      <div className="col-3">
        <Panel activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
      </div>
      <div className="col-9">
        <Summary />
        {activeIndex === 0 && <AnimalOfficers />}
        {activeIndex === 1 && (
          <div>
            <Reports />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
